$(document).ready(function($) {

    //date picker for blog posts
    $("#datepicker").datepicker({
        dateFormat: "MM d, yy",
        altFormat: "yy-m-d 01:01:11",
        altField: '#datepicker_alt'
    });

    //accordion for project management admin page
    $('.accordion').accordion({
        collapsible: true,
        active: false,
        autoHeight: false,
        heightStyle: "content"
    });

    $(".accordion.active").accordion("option", "active");

    //to allow ajax calls to laravel
    var csrfToken = $('meta[name="csrfToken"]').attr('content')

    //used on Jobs page to expand job description
    $(".expandContent").hide();
    $(".expandControl").click(function() {
        $(this).next(".expandContent").toggle();
    });

    //used to add 'active' class to project thumbnail if you are currently on its page
    if($('.projects_wrap').length) {
        new Mavigator('.projects_wrap', {warnIfLinkWasntFound: false, classToParent: true});
    }


    //sucks to do it this way but doesn't seem like a way around it
    //https://github.com/LPology/Simple-Ajax-Uploader/issues/27
    $('.upload-button').each(function() {
        var uploader = new ss.SimpleUpload(getUploadOptions($(this).data('attribute')));
        //var uploader = new ss.SimpleUpload(getUploadOptions('project-thumbnail-input', 'project-thumbnail-preview'));
    });
    //var uploader = new ss.SimpleUpload(getUploadOptions('project-thumbnail-input', 'project-thumbnail-preview'));

    function getUploadOptions(attribute) {
        return {
            button: (attribute + "_button"),
            url: '/uploadplain', // URL of server-side upload handler
            name: 'userfile', // Parameter name of the uploaded file
            customHeaders: {'X-CSRF-TOKEN': csrfToken},
            onSubmit: function(filename, extension) {
                $('.' + attribute + '-progress').show();
            },
            onComplete: function(filename, response) {
                var responseObj = JSON.parse(response);
                var inputElement = '#' + attribute;
                var previewElement = '.' + attribute + "-preview";
                $(inputElement).val(responseObj.file);
                $(previewElement).attr('src', responseObj.file).show();
                $('.' + attribute + '-progress').hide();
            }
        };
    }

    //======================================================
    //Projects Carousel

    function initSlick(category) {
        $('.carousel.' + category + ' ul').slick({
            infinite: false,
            slidesToShow: 8,
            slidesToScroll: 8,
            arrows: false,
            dots: true,
            appendDots: $('.carousel.' + category),
            speed: 250,
            cssEase: 'easeInQuad',
            easing: 'easeInQuad'
        });
    }

    initSlick('web');
    initSlick('games');
    initSlick('animation');

    //to select correct page and category for current project being viewed
    var activeCategory;
    var foundCategory = false;
    $('li.project-thumb').each(function () {
        if ($(this).hasClass('active')) {
            var category = $(this).closest('ul').data('category');
            activeCategory = $('.projects_nav li.' + category).addClass('active');

            $('.projects_wrap .projects').addClass(category);

            jumpToProject(category, $(this).data('slick-index')); //having to use this due to slick bug
            //$('.carousel.' + category + ' ul').slick('slickGoTo', $(this).data('slick-index'), true); //can't use because of slick bug

            foundCategory = true;
            return false;
        }
    });

    //this is used due to slick.js bug, https://github.com/kenwheeler/slick/issues/1802
    function jumpToProject(category, index) {
        try {
            $('.carousel.' + category + ' ul').slick("slickGoTo", index, true);
        }
        catch(error) {
            setTimeout(jumpToProject, 100);
            return;
        }

        $('.carousel.' + category + ' ul').slick('slickGoTo', index, true);

    }


    if(!foundCategory) {
        activeCategory = $('.projects_nav li.web').addClass('active'); //default
    }

    //animating mouse hovering on categories
    $(".projects_nav ul").mouseleave(function () {
            $(".projects_nav li").removeClass('active');
            activeCategory.addClass('active');
        }
    );

    //animating mouse hovering on categories
    $(".projects_nav li").hover(function () {
            $(".projects_nav li").removeClass('active');
            $(this).toggleClass('active');
        }
    );

    //changing category
    $(".projects_nav li").click(function () {

            $('.projects_wrap .projects').removeClass('web');
            $('.projects_wrap .projects').removeClass('games');
            $('.projects_wrap .projects').removeClass('animation');

            if($(this).hasClass('web')) {
                $('.projects_wrap .projects').addClass('web');
                activeCategory.removeClass('active');
                activeCategory = $('.projects_nav li.web').addClass('active');
            }

            if($(this).hasClass('games')) {
                $('.projects_wrap .projects').addClass('games');
                activeCategory.removeClass('active');
                activeCategory = $('.projects_nav li.games').addClass('active');
            }

            if($(this).hasClass('animation')) {
                $('.projects_wrap .projects').addClass('animation');
                activeCategory.removeClass('active');
                activeCategory = $('.projects_nav li.animation').addClass('active');
            }
        }
    );

    // Animate the thumb when hover, if not active
    $("li.project-thumb").hover(function () {
            $(this).toggleClass('active');
        }
    );

    //make letter spacing less cramped if project title short enough
    $('.project-thumb .thumblink div').each(function() {
        if($(this).text().length < 15) {
            $(this).css('letter-spacing', '0px');
        }
    });

    //End Project Carousel
    //======================================================

    //for ordering items in admin dashboard
    $( ".sortable" ).sortable({
        update: function( event, ui ) {
            var sorted = $(this).sortable("toArray");
            var objectType = $(this).data("type");
            $.ajax({
                url: '/saveOrder',
                type: 'post',
                data: {type: objectType, list: sorted, _token: csrfToken},
                success: function () {
                    
                },
                error: function (){

                }
            });


        }
    });

    //to prevent jumping when loading page
    $('.carousel-placeholder').hide();
    $('.projects_wrap').show();

});